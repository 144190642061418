.Password-Expirypage{
    height: calc(100vh - 50px);
}
.exp-msg{
    img{
        height:350px;
    }
    h3{
        margin-top: 20px;
        color: #A4523F;
        font-weight: 900;   
    }
    p{
        margin-top: 20px;
        color: #707070;
    }
}
.pw-chng-area{
    margin-top: 30px;
}

.exp-change-btn{
    button{
        float: none !important;
        margin: auto !important;
    }
}